(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/faq/assets/javascripts/index.js') >= 0) return;  svs.modules.push('/widgets/faq/assets/javascripts/index.js');

'use strict';

const logger = new svs.core.log.Logger('widget:faq');
$(() => {
  var _svs;
  const widgets = ((_svs = svs) === null || _svs === void 0 || (_svs = _svs.widgets) === null || _svs === void 0 || (_svs = _svs.faq) === null || _svs === void 0 || (_svs = _svs.data) === null || _svs === void 0 ? void 0 : _svs.widgets) || [];
  let arrFaqJsonLdMainEntity = [];
  widgets.forEach(widget => {
    if (widget.component === 'widgets/faq') {
      var _widget$config;
      if (widget !== null && widget !== void 0 && (_widget$config = widget.config) !== null && _widget$config !== void 0 && _widget$config.faqSearchEngineOptimization && widget !== null && widget !== void 0 && widget.faqListJsonLdMainEntity) {
        arrFaqJsonLdMainEntity = arrFaqJsonLdMainEntity.concat(widget.faqListJsonLdMainEntity);
      }
    }
  });
  if (arrFaqJsonLdMainEntity.length > 0) {
    const faqJsonLd = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: arrFaqJsonLdMainEntity
    };
    const script = document.createElement('script');
    script.setAttribute('type', 'application/ld+json');
    script.textContent = JSON.stringify(faqJsonLd);
    document.head.appendChild(script);
  }
});
function init(options) {
  var _options$model;
  const accordionOptions = {
    customer: svs.core.detect.tenant('lb') ? 'sport' : svs.core.detect.tenant('tb') ? 'tur' : 'neutral',
    allowMultipleOpen: true,
    el: "widget-faq-accordion-".concat(options === null || options === void 0 || (_options$model = options.model) === null || _options$model === void 0 ? void 0 : _options$model.widgetId),
    clickCallback: (_attribs, evt) => {
      try {
        var _evt$currentTarget, _evt$currentTarget2;
        const title = (evt === null || evt === void 0 || (_evt$currentTarget = evt.currentTarget) === null || _evt$currentTarget === void 0 ? void 0 : _evt$currentTarget.innerText) || 'Error getting accordion title';
        const isExpanded = (evt === null || evt === void 0 || (_evt$currentTarget2 = evt.currentTarget) === null || _evt$currentTarget2 === void 0 || (_evt$currentTarget2 = _evt$currentTarget2.nextElementSibling) === null || _evt$currentTarget2 === void 0 ? void 0 : _evt$currentTarget2.ariaHidden) === 'false';
        if (isExpanded) {
          var _options$model2, _options$model3, _options$model4, _options$model5;
          const trackEvt = {
            category: "faq-widget",
            action: options !== null && options !== void 0 && (_options$model2 = options.model) !== null && _options$model2 !== void 0 && _options$model2.title && (options === null || options === void 0 || (_options$model3 = options.model) === null || _options$model3 === void 0 ? void 0 : _options$model3.title.toString().trim().length) > 0 ? options === null || options === void 0 || (_options$model4 = options.model) === null || _options$model4 === void 0 ? void 0 : _options$model4.title : (options === null || options === void 0 || (_options$model5 = options.model) === null || _options$model5 === void 0 ? void 0 : _options$model5.widgetId) || 'No widget title or id',
            opt_label: title
          };
          svs.components.analytics.trackEvent(trackEvt);
        }
      } catch (e) {
        logger.info("Failed clickCallback in faq widget: ".concat(e.toString()));
      }
    }
  };
  new svs.ui.Accordion(accordionOptions);
}
svs.widget = svs.widget || {};
svs.widget.faq = svs.widget.faq || {};
svs.widget.faq.init = init;

 })(window);